<template>
  <div class="d-flex justify-center">
    <v-alert
      :value="value"
      border="right"
      class="mx-1"
      dense
      dismissible
      elevation="5"
      id="renewal-alert"
      prominent
      transition="slide-fade"
      type="info"
    >ඔබගේ පැකේජයට අදාල කාල සීමාව අවසන් වීමට ආසන්න බැවින් ගෙවීමක් සිදුකර අලුත් කරගන්න</v-alert>
  </div>
</template>

<script>
export default {
  props: ['value']
}
</script>

<style scoped>
#renewal-alert {
  position: fixed;
  top: 70px;
  z-index: 100;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>