<template>
  <v-app>
    <Alert />

    <!-- Overlay -->
    <v-overlay :value="$store.state.progress" opacity="0.8" style="z-index: 1000">
      <v-progress-circular color="accent" indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer">
      <v-list dense nav>
        <v-list-item :key="i" :to="item.link" color="white" link v-for="(item, i) in items">
          <v-list-item-avatar>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 py-2">{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="ml-0 pl-4" style="width: 300px">
        <router-link to="/">
          <v-img alt="Vuetify" max-height="60" max-width="150" src="../../assets/images/logo.png" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <marquee v-if="activeRole == 0 && $vuetify.breakpoint.name != 'xs'">
        ආශිර්වාද මංගල සේවය සඳහා ලියාපදිංචිය සම්පූර්ණයෙන්ම නොමිලේ ! ඔබට විශ්වාසවන්ත හා ආරක්ෂාකාරී සේවාවක් උපරිමයෙන් ලබාදීම අපගේ බලාපොරොත්තුවයි ! ඔබට ගැළපෙන සහකරු/සහකාරිය ඉතා ඉක්මනින්
        අප සොයා දෙන්නෙමු. විදෙස්ගතව සිටින ඔබටද ආශිර්වාද සාමාජිකත්වය ලබාගත හැකිය. | අපව අමතන්න 071-792 8627 , 071-792 8628
      </marquee>
      <div class="d-flex mx-4" id="avatar-container" v-if="activeRole == 0">
        <v-avatar size="36">
          <v-img :src="$store.state.profilePicture" alt="avatar">
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular class="mx-auto my-auto" color="grey lighten-5" indeterminate></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <span class="title roboto ml-5 mr-1">{{username}}</span>
      </div>
      <v-btn @click="logout" class="mr-1" icon large>
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <v-row>
          <keep-alive
            :include="['members', 'approvedMembers', 'suspendedMembers', 'matchedProposals']"
          >
            <router-view :key="$route.fullPath"></router-view>
          </keep-alive>
        </v-row>
        <RenewalNotice :value.sync="timeToRenewal" />

        <v-snackbar :timeout="timeout" absolute color="info" right top v-model="snackbar">
          {{ snackbarText }}
          <v-btn @click="snackbar = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-content>

    <ConfirmDialog ref="confirm" />
  </v-app>
</template>

<script>
import { UserService } from "../../services/user.service";
import { ApiService } from "../../services/api.service";
import { TokenService } from '../../services/storage.service';
import { LOAD_PROFILE_PICTURE, CHANGE_LOADING_STATE } from "../../store/mutation-types";
import Alert from '../../components/Alert';
import ConfirmDialog from "../ConfirmDialog";
import RenewalNotice from "../RenewalNotice";

export default {
  name: "mainLayout",

  components: {
    Alert,
    ConfirmDialog,
    RenewalNotice
  },

  data: () => ({
    username: null,
    drawer: true,
    activeRole: 0, // Default activeRole is customer
    items: [
      // {
      //   icon: "mdi-view-dashboard",
      //   text: "ප්‍රධාන පුවරුව",
      //   link: "/dashboard/index"
      // },
      {
        icon: "mdi-contacts",
        text: "සාමාජික ගැලරිය",
        link: "/dashboard/members"
      },
      {
        icon: "mdi-camera-account",
        text: "ෆොටෝ ඇරයුම්",
        link: "/dashboard/photo-requests"
      },
      {
        icon: "mdi-folder-account",
        text: "හඳහන් ඇරයුම්",
        link: "/dashboard/horoscope-requests"
      },
      {
        icon: "mdi-hand-heart",
        text: "හඳහන් ගලපන්න",
        link: "/dashboard/horoscope-match"
      },
      {
        icon: "mdi-account-box",
        text: "මගේ ගිණුම",
        link: "/dashboard/my-profile"
      },
      {
        icon: "mdi-account-heart",
        text: "කැමතිම සාමාජිකයන්",
        link: "/dashboard/favourites"
      },
      {
        icon: "mdi-bag-personal",
        text: "පැකේජයන්",
        link: "/dashboard/package"
      },
      {
        icon: "mdi-cog",
        text: "සැකසුම්",
        link: "/dashboard/settings"
      },
      {
        icon: "mdi-home",
        text: "මුල් පිටුව",
        link: "/"
      }
    ],
    timeToRenewal: false,
    snackbar: false,
    snackbarText: '',
    timeout: 10000
  }),

  mounted: function () {
    this.detectUserRole();
    if (!UserService.isAdmin()) {
      this.checkNewRequest();
      this.$store.dispatch('loadProfilePicture');
      setTimeout(function () { this.checkPackageStatus() }.bind(this), 5000);
    }
    this.username = UserService.getUserPayload().sub;
  },

  methods: {
    detectUserRole: function () {
      if (UserService.isAdmin()) {
        this.activeRole = 1;
        // Set admin panel 
        this.items = [
          {
            icon: "mdi-view-dashboard",
            text: "ප්‍රධාන පුවරුව",
            link: "/aw-admin/dashboard"
          },
          {
            icon: "mdi-account-plus",
            text: "නව සාමාජිකයින්",
            link: "/aw-admin/new-members"
          },
          {
            icon: "mdi-account-check",
            text: "අනුමත සාමාජිකයින්",
            link: "/aw-admin/approved-members"
          },
          {
            icon: "mdi-account-cancel",
            text: "අත්හිටවූ සාමාජිකයින්",
            link: "/aw-admin/suspended-members"
          },
          {
            icon: "mdi-package-down",
            text: "පැකේජ ඉල්ලීම්",
            link: "/aw-admin/package-requests"
          },
          {
            icon: "mdi-package",
            text: "පැකේජ විස්තර",
            link: "/aw-admin/packages"
          },
          {
            icon: "mdi-history",
            text: "පැකේජ භාවිතය",
            link: "/aw-admin/package-usage"
          },
          {
            icon: "mdi-view-carousel",
            text: "දැන්වීම්",
            link: "/aw-admin/advertisements"
          },
          {
            icon: "mdi-cog",
            text: "සැකසුම්",
            link: "/aw-admin/settings"
          },
        ]
      }
    },

    logout: async function () {
      if (await this.$refs.confirm.open('ඉවත් වන්න', 'ගිණුමෙන් ඉවත්වීමට අවශ්‍යද ?', { color: 'info' })) {
        this.$store.commit(CHANGE_LOADING_STATE, { active: true });
        try {
          const response = await UserService.logout(this.activeRole);
          this.$store.commit(LOAD_PROFILE_PICTURE); // Reset profile picture
          if (response.status == 200) {
            this.$router.push('/');
          }
        } catch (error) {
          console.log(error);
        }
        this.$store.commit(CHANGE_LOADING_STATE, { active: false });
      }
    },

    // Check whether renewal notice show or not
    checkPackageStatus: async function () {
      try {
        const url = 'customers/check-package-status';
        const response = await ApiService.get(url);
        this.timeToRenewal = response.data.timeToRenewal;
      } catch (error) {
        console.log(error);
      }
    },

    // Check new requests
    checkNewRequest: async function () {
      try {
        const url = 'customers/check-new-requests';
        const response = await ApiService.get(url);
        const { photo, horoscope } = response.data;

        if (photo || horoscope) {
          const photoRequestText = `ෆොටෝ ඇරයුම් ${photo}ක්`;
          const horoscopeRequestText = `හඳහන් ඇරයුම් ${horoscope}ක්`;
          this.snackbarText = `ඔබට නව ${photo ? photoRequestText : ''} ${photo && horoscope ? 'සහ' : ''} ${horoscope ? horoscopeRequestText : ''} ලැබී ඇත.`
          this.snackbar = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
#avatar-container {
  border: 1px solid #1480be;
  border-radius: 50px;
  padding: 3px;
}
.v-list-item {
  text-decoration: none !important;
}
.v-list .v-list-item--active {
  background-color: #0277bd;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}
</style>