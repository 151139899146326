<template>
  <div class="d-flex justify-center">
    <v-alert
      :border="this.alert.border"
      :color="this.alert.color"
      :dense="this.alert.dense"
      :dismissible="this.alert.dismissible"
      :elevation="this.alert.elevation"
      :icon="this.alert.icon"
      :min-width="this.alert.minWidth"
      :type="this.alert.type"
      :value="this.alert.value"
      @input="this.dismissAlert"
      class="custom-alert mx-1"
      transition="slide-fade"
    >{{this.alert.message}}</v-alert>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { HIDE_ALERT } from "../store/mutation-types";

export default {
  data: () => ({
    timer: null
  }),

  computed: {
    ...mapState(["alert"])
  },

  watch: {
    alert: {
      handler: function (newState, oldState) {
        if (newState.value) {
          this.timer = setTimeout(() => this.hideAlert(), this.alert.duration);
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations({
      hideAlert: HIDE_ALERT
    }),

    dismissAlert: function () {
      this.hideAlert();
      clearTimeout(this.timer);
    }
  }
};
</script>

<style scoped>
.custom-alert {
  position: fixed !important;
  bottom: 20px;
  /* left: 50%;
  transform: translateX(-50%); */
  z-index: 10000;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
</style>